import { SentryClient } from "msg-sentry-client-web";


// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');

SentryClient.start((user?: Oidc.User) =>
    {
        console.log(user); // who am I ?
        import("./App");
    });

//const startApp = async function () {

//  ReactDOM.render(
//        <App />,
//    rootElement
//  );
//};